<template>
    <div>
        <el-container>
            <el-main>
                <head-layout
                    head-title="设备维修记录"
                    :head-btn-options="headBtnOptions"
                    @head-cancel="handleHeadGoback"
                    @head-save="headSave(1)"
                    @head-submit="headSave(2)"
                ></head-layout>
                <avue-form :option="formOptions" ref="formLayout" v-model="dataForm" style="padding:10px;" >
                    <template slot="recordCode">
                        <el-input v-model="dataForm.recordCode" maxlength="100" :disabled="type != 'add'">
                            <span slot="append">
                                自动生成
                                <el-switch v-model="generate" @change="handleSwidth" :disabled="type != 'add'"></el-switch>
                            </span>
                        </el-input>
                    </template>
                    <template slot="equipmentCode">
                        <el-input v-model="dataForm.equipmentCode" ref="equipmentCode" @focus="changeEquipment" :disabled="type == 'view'" suffix-icon="el-icon-search"></el-input>
                    </template>
                    <template slot="userName">
                        <el-input v-model="dataForm.userName" ref="userName" @focus="changeUser" :disabled="type == 'view'" suffix-icon="el-icon-search"></el-input>
                    </template>
                </avue-form>
                <el-dialog title="人员选择" :visible.sync="userVisible" width="80%" top="8vh">
                    <UserDetpDialog ref="UserDetpDialog" @select-data="selectData" />
                </el-dialog>
            </el-main>
        </el-container>
        <equipmentDialog ref="equipmentDialog" @callback="getEquipment"/>
    </div>
</template>

<script>
import headLayout from '@/views/components/layout/head-layout.vue'
import { getPageCode } from '@/api/system/serialNumber'
import UserDetpDialog from '@/views/components/UserDeptDialog/UserDetpDialog'
import {serviceRecordDetail,serviceRecordSubmit} from '@/api/equipmentFacilities/equipmentMaintenance'
import equipmentDialog from '@/views/equipmentFacilities/equipmentMaintenance/compoment/equipmentDialog.vue'
import {formatTime} from "@/util";
import { mapGetters } from "vuex";
export default {
    components: {
        headLayout,
        UserDetpDialog,
        equipmentDialog
    },

    data () {
        return {
            nodeData: {},
            type: '',
            dataForm: {},
            generate: true,
            userVisible: false,
        }
    },

    computed: {
      ...mapGetters(["userInfo"]),
        headBtnOptions () {
            let buttonBtn = [];
            if (this.type != "view") {
            buttonBtn.push({
                label: this.$t(`cip.cmn.btn.addBtn`),
                emit: "head-save",
                type: "button",
                icon: "",
                btnOptType: "save",
            });
            buttonBtn.push({
                label: '提交',
                emit: "head-submit",
                type: "button",
                icon: "",
                btnOptType: "submit",
            });
            }
            buttonBtn.push({
                label: this.$t("cip.cmn.btn.celBtn"),
                emit: "head-cancel",
                type: "button",
                icon: "",
            });
            return buttonBtn;
        },

        formOptions () {
            return {
                size: 'small',
                submitBtn: false,
                emptyBtn: false,
                labelWidth: 100,
                column: [
                    {
                        label: '记录编号',
                        prop: 'recordCode',
                        span: 8,
                        formslot: true,
                        maxlength: 200,
                        rules: [
                            {required: true, message: '请输入记录编号', trigger: ['change','blur']}
                        ]
                    },
                    {
                        label: '设备编号',
                        prop: 'equipmentCode',
                        span: 8,
                        formslot: true,
                        rules: [
                            {required: true, message: '请选择设备', trigger: 'change'}
                        ]
                    },
                    {
                        label: '设备名称',
                        prop: 'equipmentName',
                        span: 8,
                        disabled: true,
                    },
                    {
                        label: '维修内容',
                        prop: 'content',
                        type: 'textarea',
                        placeholder: "请输入维修内容",
                        span: 16,
                        maxlength: 255,
                        howWordLimit: true,
                        rules: [
                            {required: true, message: '请输入维修内容', trigger: 'blur'}
                        ]
                    },
                    {
                        label: '维修单位',
                        prop: 'repairOrg',
                        placeholder: "请输入维修单位",
                        type: 'textarea',
                        span: 8,
                        maxlength: 255,
                        showWordLimit: true,
                        rules: [
                            {required: true, message: '请输入维修单位', trigger: 'blur'}
                        ]
                    },
                    {
                        label: '开始日期',
                        prop: 'repairDate',
                        span: 8,
                        type: "date",
                        placeholder: '请选择维修开始日期',
                        format: "yyyy-MM-dd", // 设置日期格式
                        valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
                        rules: [
                            {
                            required: true,
                            message: "请选择维修开始日期",
                            trigger: "change",
                            },
                        ],
                    },
                    {
                        label: '结束日期',
                        prop: 'repairDateEnd',
                        span: 8,
                        type: "date",
                        placeholder: '请选择维修结束日期',
                        format: "yyyy-MM-dd", // 设置日期格式
                        valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
                        rules: [
                            {
                            required: true,
                            message: "请选择维修结束日期",
                            trigger: "change",
                            },
                        ],
                    },
                    {
                        label: "负责人",
                        prop: "userName",
                        placeholder: "请选择负责人",
                        span: 8,
                        formslot:true,
                    },
                    {
                        label: '维修结果',
                        prop: 'repairResult',
                        type: 'textarea',
                        span: 24,
                        maxlength: 255,
                        showWordLimit: true,
                        placeholder: '请输入维修结果',
                        rules: [
                            {
                            required: true,
                            message: "请输入维修结果",
                            trigger: "blur",
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'remark',
                        type: 'textarea',
                        span: 24,
                        maxlength: 255,
                        showWordLimit: true,
                        placeholder: '请输入备注说明'
                    },
                ]
            }
        }
    },

    created () {
        this.nodeData = this.$route.query.row ? JSON.parse(this.$route.query.row) : {};
        this.type = this.$route.query.type;
        if(this.nodeData.id){
            this.getDetail(this.nodeData.id)
        } else {
            this.handleSwidth(this.generate)
            this.dataForm.repairDate = formatTime(new Date(), "yyyy-MM-dd")
            if(this.nodeData.equipmentId){
                this.dataForm.equipmentName = this.nodeData.equipmentName
                this.dataForm.equipmentCode = this.nodeData.equipmentCode
                this.dataForm.equipmentId = this.nodeData.equipmentId
                this.dataForm.orgId = this.nodeData.orgId
                this.dataForm.orgCode = this.nodeData.orgCode
            }
        }
        if(this.type=='add'){
          this.dataForm.repairOrg = this.userInfo.deptName
        }
    },

    mounted () {
        if(this.type == 'view'){
            this.$refs.formLayout.allDisabled = true
        }
    },

    methods: {
        handleHeadGoback () {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },

        getDetail (id) {
            // 获取详情
            serviceRecordDetail(id).then(res=>{
                this.dataForm = res.data.data
            })
        },

        handleSwidth (val) {
            if(val) {
                getPageCode({ruleCode: 'eq_repair_code'}).then(res=>{
                  if(res.data.data){
                        this.dataForm.recordCode = res.data.data
                    }
                })
            }
        },

        // 设备选择
        changeEquipment () {
            this.$refs.equipmentCode.blur()
            this.$refs.equipmentDialog.init({orgId:this.nodeData.orjId})
        },

        getEquipment (data) {
            this.dataForm.equipmentName = data.equipmentName
            this.dataForm.equipmentCode = data.equipmentCode
            this.dataForm.equipmentId = data.id
            this.dataForm.orgId = data.orgId
            this.dataForm.orgCode = data.orgCode
        },

        // 人员选择
        changeUser () {
            this.userVisible = true
            this.$refs.userName.blur()
        },

        selectData(row,deptId,deptName){
            this.dataForm.userId = row.id
            this.dataForm.userName = row.realName
            this.userVisible = false
        },

        headSave (val) {
            this.$refs.formLayout.validate((valid, done) => {
                if(valid){
                    if(this.dataForm.repairDateEnd < this.dataForm.repairDate){
                        this.$message.warning('维修结束时间不能小于开始时间')
                    } else {
                        // if(!this.dataForm.status){
                            this.dataForm.eqRepairStatus = val==1 ? 0 : 1
                        // }
                        serviceRecordSubmit(this.dataForm).then(res =>{
                          this.$message.success(
                            this.$t("cip.cmn.msg.success.operateSuccess")
                          );
                            if (val == 1 && !this.nodeData.id) {
                                // this.$message.success('保存成功')
                                this.dataForm.id = res.data.data.id
                                this.$router.$avueRouter.closeTag();
                                this.$router.replace({
                                    path: "/equipmentFacilities/equipmentMaintenance/service/form",
                                    query: {
                                    row: JSON.stringify({ id: res.data.data.id }),
                                    type: "edit",
                                    },
                                });
                            }
                            if (val == 2) {
                                this.handleHeadGoback();
                            }
                        })
                    }
                    done()
                }
            })
        },
    }

}
</script>

<style>

</style>
